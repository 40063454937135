import { Col, Container, Row } from "codeebo-react-grid";
import BoxTile from "../../components/BoxTile/BoxTile";
import TextBlock from "../../components/text-block/text-block";
import TopLink from "../../components/top-link/top-link";
import { TreningDto } from "../../types/trening.dto";
import { TreningData } from "../../_data/TreningData";
import { useEffect } from "react";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import PwaInstallPrompt from "react-pwa-install-prompt";
import usePWA from "react-pwa-install-prompt";
import "./StartPage.scss";

export const StartPage = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { isStandalone, isInstallPromptSupported, promptInstall } = usePWA();
  useEffect(() => {
    // pwa.install(undefined);
  }, []);

  const onClickInstall = async () => {
    const didInstall = await promptInstall();
    if (didInstall) {
      // User accepted PWA install
    }
  };

  return (
    <Container className="StartPage">
      <Row className="pt-5">
        <Col size={[6, 8, 12, 12]} offset={[3, 2, 0, 0]}>
          <BoxTile>
            <TextBlock>
              <h1 style={{ marginTop: 0, paddingTop: 0 }}>Start</h1>
              <h3 className="pt-3">Wyszukaj szkolenie</h3>
              <div className="info-flex">
                <p>Zeskanuj kod QR aby rozpocząć szkolenie używając ikony znajdującej się w górnym prawym rogu ekranu.</p>
                <img className="qr-search" src="/images/qr-search.svg" alt="qr-search" width="64px" height="auto" />
              </div>
              <br />
              {isInstallPromptSupported ? <button onClick={onClickInstall}>Zainstaluj aplikację</button> : null}
              <br />
              <br />
              <h3 className="pt-3">Dostępne szkolenia</h3>
              <ul>
                {TreningData.map((x: TreningDto, i: number) => {
                  var firstElement = x.exercises[0];
                  return <li key={i}>{firstElement ? <TopLink to={`/szkolenie/${x.id}/instrukcja/${firstElement.id}`}>{x.name}</TopLink> : <TopLink to={`/szkolenie/${x.id}`}>{x.name}</TopLink>}</li>;
                })}
              </ul>
            </TextBlock>
          </BoxTile>
        </Col>
      </Row>
    </Container>
  );
};
