import { Grid } from "codeebo-react-grid";
import { Outlet } from "react-router";
import "./App.scss";
import RouterTransition from "./components/router-transition/router-transition";
import SideNav from "./components/SideNav/SideNav";
import { TopNav } from "./components/TopNav/TopNav";
import usePWA from "react-pwa-install-prompt";
import { useEffect } from "react";

export const App = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { isStandalone, isInstallPromptSupported, promptInstall } = usePWA();

  useEffect(() => {
    setTimeout(() => {
      if(isStandalone){
        promptInstall();
      }
    }, 31000);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>
      <Grid>
        <TopNav />
        <div className="App">
          <RouterTransition type="panel" />
          <div className="app-body">
            <div className="side-nav-wrapper">
              <SideNav />
            </div>
            <main>
              <Outlet />
            </main>
          </div>
        </div>
      </Grid>
    </div>
  );
};
