import "./UserAvatar.scss";

export const UserAvatar = () => {
  return (
    <div className="UserAvatar">
      <div className="UserInfo">
        <span className="UserInfo-name">Adam Nowak</span> <br />
        <span className="UserInfo-role">kursant</span>
      </div>
      <img src="/images/avatar.svg" alt="Avatar" />
    </div>
  );
};
