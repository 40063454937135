class EuroSizeHelper {
  static GenerateClasses = (prefix: string, arr: Array<"auto" | 0 | 1 | 2 | 3 | 4 | 5>) => {
    if (arr.length === 1) return `${prefix}-${arr[0]}`;
    if (arr.length === 2) return `${prefix}-${arr[1]} ${prefix}-xl-${arr[0]}`;
    if (arr.length === 3) return `${prefix}-${arr[2]} ${prefix}-lg-${arr[1]} ${prefix}-xl-${arr[0]}`;
    if (arr.length === 4) return `${prefix}-${arr[3]} ${prefix}-md-${arr[2]} ${prefix}-lg-${arr[1]} ${prefix}-xl-${arr[0]}`;
    if (arr.length === 5) return `${prefix}-${arr[4]} ${prefix}-sm-${arr[3]} ${prefix}-md-${arr[2]} ${prefix}-lg-${arr[1]} ${prefix}-xl-${arr[0]}`;
  };
}

export default EuroSizeHelper;
