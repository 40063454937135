import ReactDOM from "react-dom/client";
import "./index.css";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { RouterProvider } from "react-router-dom";
import { router } from "./router";
import { store } from "./redux/store";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import React from "react";

// if ('beforeinstallprompt' in window) {
//   alert('beforeinstallprompt is supported');
// } else {
//   alert('beforeinstallprompt is not supported');
// }

// if ((window.navigator as any).standalone) {
//   alert('App is running as standalone');
// } else {
//   alert('App is running in a browser');
// }

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistStore(store)}>
        <RouterProvider router={router as any} />
      </PersistGate>
    </Provider>
  </>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();
