import { useEffect, useState } from "react";
import { ExerciseDto, TreningDto } from "../../types/trening.dto";
import "./ProgressBar.scss";
interface Props {
  trening?: TreningDto;
  exercise?: ExerciseDto;
}

const ProgressBar = (props: Props) => {
  const { trening, exercise } = props;
  const calcProgress = () => (trening?.exercises.findIndex((x) => x.id === exercise?.id) ?? 0) + 1;
  const calcFullBar = () => trening?.exercises.length ?? 1;
  const [progress, setProgress] = useState<number>(calcProgress());

  useEffect(() => {
    setProgress(calcProgress());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exercise]);

  return <div className="ProgressBar" style={{ backgroundImage: `linear-gradient(90deg, #00e047 0%, #00e047 ${(progress * 99.99) / calcFullBar()}%, #f2f2f2 ${(progress * 100) / calcFullBar()}%, #f2f2f2 100%)` }} />;
};

export default ProgressBar;
