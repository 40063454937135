import { Row, Col } from "codeebo-react-grid";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import BoxTile from "../../components/BoxTile/BoxTile";
import FilesBox from "../../components/FilesBox/FilesBox";
import InfoBar from "../../components/InfoBar/InfoBar";
import ProgressBar from "../../components/ProgressBar/ProgressBar";
import NextPrevNav from "../../components/NextPrevNav/NextPrevNav";
import TextBlock from "../../components/text-block/text-block";
import { ExerciseDto, MediaDto, TreningDto } from "../../types/trening.dto";
import { TreningData } from "../../_data/TreningData";
import "./ExercisePage.scss";

export const ExercisePage = () => {
  const { treningId, exerciseId } = useParams<{ treningId: string; exerciseId: string }>();

  const [hideMedia, setHideMedia] = useState<boolean>(false);
  const [trening, setTrening] = useState<TreningDto>();
  const [exercise, setExercise] = useState<ExerciseDto>();
  const [activeMedia, setActiveMedia] = useState<MediaDto>();

  const [notFound, setNotFound] = useState<boolean>(false);
  const [notFoundExercise, setNotFoundExercise] = useState<boolean>(false);

  useEffect(() => {
    setTrening(undefined);
    if (treningId) {
      const trening = TreningData.find((x) => x.id === treningId);
      if (trening) setTrening(trening);
      else setNotFound(true);
    }
  }, [treningId]);

  useEffect(() => {
    if (exerciseId && trening) {
      const exercise = trening.exercises.find((x) => x.id === exerciseId);
      if (exercise) setExercise(exercise);
      else setNotFoundExercise(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trening, exerciseId]);

  useEffect(() => {
    setHideMedia(true);
    if (exercise?.media && exercise.media[0]) {
      setActiveMedia(exercise.media[0]);
    }
    setTimeout(() => {
      setHideMedia(false);
    }, 50);
  }, [exercise]);
  const [isZoomedIn, setIsZoomedIn] = useState(false);

  return (
    <>
      <InfoBar trening={trening} exercise={exercise} />
      <div className="page-main-content">
        {trening && exercise ? (
          <>
            <Row className="mt-1" gap={16}>
              <Col size={12} className="pb-3">
                <ProgressBar trening={trening} exercise={exercise} />
              </Col>
              <Col size={[6, 6, 6, 12]}>
                {activeMedia && hideMedia !== true ? (
                  <>
                    {isZoomedIn || activeMedia.type ? (
                      <div
                        className={`hidden-img-main ${isZoomedIn ? "isZoomedIn" : ""}`}
                        style={{ backgroundImage: `url("/images/img_placeholder.png")` }}
                        onClick={(e) => {
                          e.stopPropagation();
                          setIsZoomedIn(false);
                        }}
                      >
                        <img className={"obj-fit-contain"} src={activeMedia?.path} alt={exercise?.name} />
                      </div>
                    ) : null}
                    <div className="main-file-exercise">
                      {activeMedia.type === "image" ? <img src={activeMedia.path} alt={exercise.name} onClick={() => setIsZoomedIn(true)} /> : null}
                      {activeMedia.type === "video" ? (
                        <video controls autoPlay>
                          <source src={activeMedia.path} />
                          Your browser does not support the video tag.
                        </video>
                      ) : null}
                    </div>
                    {exercise.media && exercise.media.length > 1 ? (
                      <div className="other-media">
                        {exercise.media.map((media: MediaDto, mediaIndex: number) => {
                          return <img onClick={() => setActiveMedia(media)} key={mediaIndex} className={media === activeMedia ? "is-active" : ""} src={media.preview ?? (media.type === "image" ? media.path : "/images/video-placeholder.svg")} alt="video" />;
                        })}
                      </div>
                    ) : null}
                  </>
                ) : null}
                <FilesBox trening={trening} exercise={exercise} />
              </Col>
              <Col size={[6, 6, 6, 12]}>
                <BoxTile>
                  <TextBlock>
                    <div className="content">{exercise.content}</div>
                  </TextBlock>
                </BoxTile>
              </Col>
            </Row>
            <NextPrevNav trening={trening} exercise={exercise} />
          </>
        ) : (
          <>
            {notFound ? (
              <TextBlock>
                <h1>Nie znaleziono szkolenia /{treningId}</h1>
                <h3 className="pt-3">Wybierz inne szkolenie lub skontaktuj się z administratorem.</h3>
              </TextBlock>
            ) : null}

            {trening && notFoundExercise ? (
              <TextBlock>
                <h1>Nie znaleziono ćwiczenia /{exerciseId}</h1>
                <h3 className="pt-3">Wybierz inne instrukcje lub skontaktuj się z administratorem.</h3>
              </TextBlock>
            ) : null}
          </>
        )}
      </div>
    </>
  );
};
