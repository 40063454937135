import { Col, Row } from "codeebo-react-grid";
import { useEffect, useState } from "react";
import { ExerciseDto, TreningDto } from "../../types/trening.dto";
import { useSidebar } from "../SideNav/toggle_sidebar_slice";
import TextBlock from "../text-block/text-block";
import TopLink from "../top-link/top-link";
import "./NextPrevNav.scss";

interface Props {
  trening?: TreningDto;
  exercise?: ExerciseDto;
}

const NextPrevNav = (props: Props) => {
  const { trening, exercise } = props;
  const [nextExercise, setNextExercise] = useState<ExerciseDto>();
  const [prevExercise, setPrevExercise] = useState<ExerciseDto>();
  const { isOpen, close, open } = useSidebar();
  useEffect(() => {
    if (exercise && trening) {
      var index = trening.exercises.findIndex((x) => x.id === exercise.id);
      if (index >= 0 && index < trening.exercises.length - 1) {
        setNextExercise(trening.exercises[index + 1]);
      } else {
        setNextExercise(undefined);
      }

      if (index >= 1) {
        setPrevExercise(trening.exercises[index - 1]);
      } else {
        setPrevExercise(undefined);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exercise]);

  return (
    <TextBlock>
      <Row className="NextPrevNav">
        <Col size={[4, 4, 4, 4]}>
          <TopLink className={`text-right ${prevExercise ? "" : "inactive"}`} to={`/szkolenie/${trening?.id}/instrukcja/${prevExercise?.id ?? exercise?.id}`}>
            <svg width="151" height="64" viewBox="0 0 151 64" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="-0.5" y="0.5" width="150" height="63" rx="7.5" transform="matrix(-1 0 0 1 150 0)" stroke="black" />
              <path d="M55.2929 33.3345C54.9024 32.944 54.9024 32.3109 55.2929 31.9203L61.6569 25.5564C62.0474 25.1658 62.6805 25.1658 63.0711 25.5564C63.4616 25.9469 63.4616 26.5801 63.0711 26.9706L57.4142 32.6274L63.0711 38.2843C63.4616 38.6748 63.4616 39.308 63.0711 39.6985C62.6805 40.089 62.0474 40.089 61.6569 39.6985L55.2929 33.3345ZM95 33.6274H56V31.6274H95V33.6274Z" fill="black" />
            </svg>
          </TopLink>
        </Col>
        <Col size={[4, 4, 4, 4]} className="text-center">
          <div className="menu-wrapper">{isOpen ? <img onClick={() => close()} src="/images/hamburger_close.svg" alt="Close menu" /> : <img onClick={() => open()} src="/images/hamburger_open.svg" alt="Open menu" />}</div>
        </Col>
        <Col size={[4, 4, 4, 4]} className="text-right">
          <TopLink className={`text-right ${nextExercise ? "" : "inactive"}`} to={`/szkolenie/${trening?.id}/instrukcja/${nextExercise?.id ?? exercise?.id}`}>
            <svg width="151" height="64" viewBox="0 0 151 64" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="0.5" y="0.5" width="150" height="63" rx="7.5" stroke="black" />
              <path d="M95.7071 33.3345C96.0976 32.944 96.0976 32.3109 95.7071 31.9203L89.3431 25.5564C88.9526 25.1658 88.3195 25.1658 87.9289 25.5564C87.5384 25.9469 87.5384 26.5801 87.9289 26.9706L93.5858 32.6274L87.9289 38.2843C87.5384 38.6748 87.5384 39.308 87.9289 39.6985C88.3195 40.089 88.9526 40.089 89.3431 39.6985L95.7071 33.3345ZM56 33.6274H95V31.6274H56V33.6274Z" fill="black" />
            </svg>
          </TopLink>
        </Col>
      </Row>
    </TextBlock>
  );
};

export default NextPrevNav;
