import { TreningDto } from "../../types/trening.dto";

export const SzkoleniesmarData: TreningDto = {
  id: "szkolenie-smar",
  name: "Szkolenie z wymiany smaru",
  emoji: "🔧",
  level: "Poziom 1",
  exercises: [
    {
      id: "instrukcja-1",
      name: "Instrukcja 1",
      stanowisko: "Robot wyburzeniowy",
      media: [
        {
          path: "/szkolenia/smar/smar_1.JPG",
          type: "image",
        },       
      ],
      content: (
        <>
          <h1>Instrukcja 1</h1>
          <p>Ustaw robota na stabilnym podłożu i w bezpiecznym miejscu. Wyłącz silnik robota!</p>
        </>
      ),
    },
    {
      id: "instrukcja-2",
      name: "Instrukcja 2",
      stanowisko: "Robot wyburzeniowy",
      media: [
        {
          path: "/szkolenia/smar/smar_2.JPG",
          type: "image",       
        }
      ],
      content: (
        <>
          <h1>Instrukcja 2</h1>
          <p>Zlokalizuj na prawym boku robota miejsce gdzie widać tubę ze smarem do młota. Sprawdź poziom smaru oznaczony na tubie w %. Jeżeli jest niski poziom smaru należy wymienić tubę na nową.</p>
        </>
      ),
    },
    {
      id: "instrukcja-3",
      name: "Instrukcja 3",
      stanowisko: "Robot wyburzeniowy",
      media: [   
        {
          path: "/szkolenia/smar/smar_3.JPG",
          type: "image",       
        },    
        {
          path: "/szkolenia/smar/smar_3a.mp4",
          preview: "/szkolenia/smar/smar_3a.JPG",
          type: "video",
        },
      ],
      content: (
        <>
          <h1>Instrukcja 3</h1>
          <p>Odkręć ręcznie tubę, kręcąc nią przeciwnie do wskazówek zegara.</p>
        </>
      ),
    },
    {
      id: "instrukcja-4",
      name: "Instrukcja 4",
      stanowisko: "Robot wyburzeniowy",
      media: [
        {
          path: "/szkolenia/smar/smar_5.JPG",
          type: "image",
        },
      ],
      content: (
        <>
          <h1>Instrukcja 4</h1>
          <p>Przygotuj nową tubę ze smarem. Jeżeli nie masz tuby należy ją zamówić na stronie: www.arerobot.com.</p>
        </>
      ),
    },
    {
      id: "instrukcja-5",
      name: "Instrukcja 5",
      stanowisko: "Robot wyburzeniowy",
      media: [
        {
          path: "/szkolenia/smar/smar_6.JPG",
          type: "image",
        },
      ],
      content: (
        <>
          <h1>Instrukcja 5</h1>
          <p>Skieruj tubę gwintem do dołu.</p>
        </>
      ),
    },
    {
      id: "instrukcja-6",
      name: "Instrukcja 6",
      stanowisko: "Robot wyburzeniowy",
      media: [
        {
          path: "/szkolenia/smar/smar_7.JPG",
          type: "image",
        },
        {
          path: "/szkolenia/smar/smar_7a.mp4",
          preview: "/szkolenia/smar/smar_7a.JPG",
          type: "video",
        },
      ],
      content: (
        <>
          <h1>Instrukcja 6</h1>
          <p>Wkręć tubę w pompę smaru, obracając ją zgodnie ze wskazówkami zegara. Uwaga gwint tuby jest plastikowy, należy wkręcać tubę do momentu aż pojawi się pierwszy opór, tak aby nie ukręcić gwintu.</p>
        </>
      ),
    },
  ],
};
