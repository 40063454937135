import { createBrowserRouter } from "react-router-dom";
import { App } from "./App";
import { ExercisePage } from "./pages/ExercisePage/ExercisePage";
import { SelectExercisePage } from "./pages/SelectExercisePage/SelectExercisePage";
import { StartPage } from "./pages/StartPage/StartPage";

export const router = createBrowserRouter([
  {
    element: <App />,
    children: [
      {
        path: "/szkolenie/:treningId/instrukcja/:exerciseId",
        element: <ExercisePage />,
      },
      {
        path: "/szkolenie/:treningId/instrukcja",
        element: <SelectExercisePage />,
      },
      {
        path: "/szkolenie/:treningId",
        element: <SelectExercisePage />,
      },
      {
        path: "/szkolenie",
        element: <StartPage />,
      },
      {
        path: "/",
        element: <StartPage />,
      },
      {
        path: "*",
        element: <StartPage />,
      },
    ],
  },
]);
