import { Col, Container, Row } from "codeebo-react-grid";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import BoxTile from "../../components/BoxTile/BoxTile";
import InfoBar from "../../components/InfoBar/InfoBar";
import TextBlock from "../../components/text-block/text-block";
import TopLink from "../../components/top-link/top-link";
import { ExerciseDto, TreningDto } from "../../types/trening.dto";
import { TreningData } from "../../_data/TreningData";

export const SelectExercisePage = () => {
  const { treningId } = useParams<{ treningId: string }>();

  const [trening, setTrening] = useState<TreningDto>();
  const [notFound, setNotFound] = useState<boolean>(false);

  useEffect(() => {
    if (treningId) {
      const trening = TreningData.find((x) => x.id === treningId);
      if (trening) setTrening(trening);
      else setNotFound(true);
    }
  }, [treningId]);

  return (
    <>
      <InfoBar trening={trening} />

      <Container className="StartPage">
        <Row className="pt-5">
          <Col size={[6, 8, 12, 12]} offset={[3, 2, 0, 0]}>
            <BoxTile>
              <TextBlock>
                {trening ? (
                  <>
                    <h1 style={{ marginTop: 0, paddingTop: 0 }}>{trening?.name}</h1>
                    <h3 className="pt-3">Wybierz instrukcje</h3>
                    <ul>
                      {trening.exercises.map((x: ExerciseDto, i: number) => {
                        return (
                          <li key={i}>
                            <TopLink to={`/szkolenie/${trening.id}/instrukcja/${x.id}`}>{x.name}</TopLink>
                          </li>
                        );
                      })}
                    </ul>
                  </>
                ) : (
                  <>
                    {notFound ? (
                      <>
                        <h1>Nie znaleziono szkolenia / {treningId}</h1>
                        <h3 className="pt-3">Wybierz inne szkolenie lub skontaktuj się z administratorem.</h3>
                      </>
                    ) : null}
                  </>
                )}
              </TextBlock>
            </BoxTile>
          </Col>
        </Row>
      </Container>
    </>
  );
};
