import React, { useState } from "react";
import { QrReader } from "react-qr-reader";
import { useNavigate } from "react-router";
import "./Qrscan.scss";

const Qrscan = () => {
  const [showScan, setShowScan] = useState(false);
  const navigate = useNavigate();

  const handleError = (err: any) => {
    console.log(err);
    // setShowScan(false);
  };

  function removeDomain(url: string) {
    var fixedUrlPre = url.replace("http://", "");
    fixedUrlPre = fixedUrlPre.replace("https://", "");
    var fixedUrl = fixedUrlPre.replace("www.", "");
    if (url.indexOf(".") === -1) return fixedUrlPre;
    fixedUrl = "https://" + fixedUrl;
    var domain = new URL(fixedUrl).hostname;
    fixedUrl = fixedUrl.replace(domain, "");
    fixedUrl = fixedUrl.replace("https://", "");

    return fixedUrl;
  }

  const handleScan = (result: any) => {
    if (result && result.text) {
      if (result.text && result.text.length > 10) {
        var text = removeDomain(result.text);
        navigate(text);
        setShowScan(false);
      }
    }
  };

  const previewStyle = {
    paddingTop: 0,
  };

  return (
    <>
      <div className="qr-scan activator" onClick={() => setShowScan(!showScan)}>
        {showScan ? "ANULUJ" : <img src="/images/qr-search.svg" alt="qr search" />}
      </div>
      {showScan ? (
        <div className="qr-scan overlay">
          <QrReader
            constraints={{ facingMode: "environment" }}
            scanDelay={500}
            className="video"
            videoContainerStyle={previewStyle}
            onResult={(result: any, error: any) => {
              if (!!result) handleScan(result);
              if (!!error) handleError(error);
            }}
          />
        </div>
      ) : null}
    </>
  );
};

export default Qrscan;
