import { Col, Row } from "codeebo-react-grid";
import { ExerciseDto, TreningDto } from "../../types/trening.dto";
import "./InfoBar.scss";
interface Props {
  trening?: TreningDto;
  exercise?: ExerciseDto;
}

const InfoBar = (props: Props) => {
  const { trening, exercise } = props;
  return (
    <Row className="InfoBar">
      <Col size={[4, 4, 4, 4]}>
        {trening && trening.name ? (
          <>
            <div className="label">Szkolenie</div>
            <div className="info">{trening.name}</div>
          </>
        ) : null}
      </Col>
      <Col size={[4, 4, 4, 4]} className="text-center">
        {exercise && exercise.stanowisko ? (
          <>
            <div className="label">Stanowisko</div>
            <div className="info">{exercise.stanowisko}</div>
          </>
        ) : null}
      </Col>
      <Col size={[4, 4, 4, 4]} className="text-right">
        {trening && trening.level ? (
          <>
            <div className="label">Level</div>
            <div className="info">{trening.level}</div>
          </>
        ) : null}
      </Col>
    </Row>
  );
};

export default InfoBar;
