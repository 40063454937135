import { Col, Row } from "codeebo-react-grid";
import { Link } from "react-router-dom";
import Qrscan from "../Qrscan/Qrscan";
// import { useSidebar } from "../SideNav/toggle_sidebar_slice";
import { UserAvatar } from "../UserAvatar/UserAvatar";
import "./TopNav.scss";

export const TopNav = () => {
  // const { isOpen, open, close } = useSidebar();
  return (
    <nav className="TopNav">
      <Row align="center">
        <Col size={[6, 6, 6, 7]}>
          {/* <div className="menu-wrapper md-md-4 mr-1">{isOpen ? <img onClick={() => close()} src="/images/hamburger_close.svg" alt="Close menu" /> : <img onClick={() => open()} src="/images/hamburger_open.svg" alt="Open menu" />}</div> */}
          <Link to={"/"}>
            <img src="/images/are_logo2.jpg" alt="Logo" className="nav-logo" />
          </Link>
        </Col>
        <Col size={[6, 6, 6, 5]} className="to-right">
          <UserAvatar />
          <Qrscan />
        </Col>
      </Row>
    </nav>
  );
};
