import { TreningDto } from "../../types/trening.dto";

export const SzkoleniewymianaData: TreningDto = {
  id: "szkolenie-wymiana",
  name: "Szkolenie z wymiany narzędzia",
  emoji: "🔧",
  level: "Poziom 1",
  exercises: [
    {
      id: "instrukcja-1",
      name: "Instrukcja 1",
      stanowisko: "Robot wyburzeniowy",
      media: [
        {
          path: "/szkolenia/wymiana/1_1.JPG",
          type: "image",
        },
      ],
      content: (
        <>
          <h1>Instrukcja 1</h1>
          <p>Przed wymianą narzędzia wyłącz silnik maszyny robota. Przy montażu i demontażu narzędzia należy umieścić młot w poziomie oraz zabezpieczyć, aby zmniejszyć ryzyko zmiażdżenia części ciała. Niektóre narzędzia są ciężkie, dlatego należy je wyjmować i odkładać w sposób bezpieczny</p>
        </>
      ),
    },
    {
      id: "instrukcja-2",
      name: "Instrukcja 2",
      stanowisko: "Robot wyburzeniowy",
      media: [
        {
          path: "/szkolenia/wymiana/1_1a.JPG",
          type: "image",
        },
      ],
      content: (
        <>
          <h1>Instrukcja 2</h1>
          <p>Przygotuj narzędzia.</p>
        </>
      ),
    },
    {
      id: "instrukcja-3",
      name: "Instrukcja 3",
      stanowisko: "Robot wyburzeniowy",
      media: [
        {
          path: "/szkolenia/wymiana/1_2.JPG",
          type: "image",
        },
      ],
      content: (
        <>
          <h1>Instrukcja 3</h1>
          <p>Zlokalizuj kołek zabezpieczający.</p>
        </>
      ),
    },
    {
      id: "instrukcja-4",
      name: "Instrukcja 4",
      stanowisko: "Robot wyburzeniowy",
      media: [
        {
          path: "/szkolenia/wymiana/1_4.JPG",
          type: "image",
        },
        {
          path: "/szkolenia/wymiana/1_4a.mp4",
          preview: "/szkolenia/wymiana/1_4a.JPG",
          type: "video",
        },
      ],
      content: (
        <>
          <h1>Instrukcja 4</h1>
          <p>Wybij kołek zabezpieczający zatrzaski narzędzia przy pomocy narzędzi do około połowy jego długości. Uwaga na niebezpieczeństwo wypadnięcia zatrzasku</p>
        </>
      ),
    },
    {
      id: "instrukcja-5",
      name: "Instrukcja 5",
      stanowisko: "Robot wyburzeniowy",
      media: [
        {
          path: "/szkolenia/wymiana/1_7.JPG",
          type: "image",
        },
        {
          path: "/szkolenia/wymiana/1_7a.mp4",
          preview: "/szkolenia/wymiana/1_7a.JPG",
          type: "video",
        },
      ],
      content: (
        <>
          <h1>Instrukcja 5</h1>
          <p>Wysuń lub wybij pierwszy zatrzask narzędzia. Sprawdź, czy zatrzask blokujący nie jest zużyty lub uszkodzony</p>
        </>
      ),
    },
    {
      id: "instrukcja-6",
      name: "Instrukcja 6",
      stanowisko: "Robot wyburzeniowy",
      media: [
        {
          path: "/szkolenia/wymiana/1_10.JPG",
          type: "image",
        },
        {
          path: "/szkolenia/wymiana/1_10a.mp4",
          preview: "/szkolenia/wymiana/1_10a.JPG",
          type: "video",
        },
      ],
      content: (
        <>
          <h1>Instrukcja 6</h1>
          <p>Wysuń do końca kołek zabezpieczający.</p>
        </>
      ),
    },
    {
      id: "instrukcja-7",
      name: "Instrukcja 7",
      stanowisko: "Robot wyburzeniowy",
      media: [
        {
          path: "/szkolenia/wymiana/1_11.JPG",
          type: "image",
        },
        {
          path: "/szkolenia/wymiana/1_11a.mp4",
          preview: "/szkolenia/wymiana/1_11a.JPG",
          type: "video",
        },
      ],
      content: (
        <>
          <h1>Instrukcja 6</h1>
          <p>Wybij drugi zatrzask narzędzia. Uwaga na niebezpieczeństwo wysunięcia się luźnego grota.</p>
        </>
      ),
    },
    {
      id: "instrukcja-8",
      name: "Instrukcja 8",
      stanowisko: "Robot wyburzeniowy",
      media: [
        {
          path: "/szkolenia/wymiana/1_12.JPG",
          type: "image",
        },
        {
          path: "/szkolenia/wymiana/1_12a.mp4",
          preview: "/szkolenia/wymiana/1_12a.JPG",
          type: "video",
        },
        {
          path: "/szkolenia/wymiana/1_13.JPG",
          type: "image",
        },
      ],
      content: (
        <>
          <h1>Instrukcja 6</h1>
          <p>Wyciągnij narzędzie i oczyść i obficie przesmaruj tuleję. Jest to szczególnie ważne przy zakładaniu nowego narzędzia. Narzędzie montuje się wykonując powyższe czynności w odwrotnej kolejności niż przy zakładaniu.</p>
        </>
      ),
    },
  ],
};
